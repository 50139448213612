import type { BrowserStorageWrapper } from '@ocodelib/ex-util';
import { useEffect, useState } from 'react';

export function useBrowserStorageValue<T>(storageWrapper: BrowserStorageWrapper<T>): T | undefined {
  const [value, setValue] = useState<T | undefined>(storageWrapper.value);
  useEffect(() => {
    setValue(storageWrapper.value);
    return storageWrapper.on(setValue);
  }, [storageWrapper]);
  return value;
}
