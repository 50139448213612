'use client';

import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { LoginProfileLoadedEvent } from '../auth/authentication';
import { useApi } from '../provider/useApi';

const MINUTE = 60_000;
const REFRESH_INTERVAL = 2 * MINUTE;

export function useLoginProfileLoad(enabled: boolean) {
  const api = useApi();
  const {
    isFetching,
    status,
    error,
    data: userProfile,
    refetch,
  } = useQuery({
    queryKey: ['api.userProfile.profileMe'],
    queryFn: () => api.userProfile.profileMe()?.then((it) => it.profile),
    refetchOnMount: true,
    refetchInterval: REFRESH_INTERVAL,
    enabled,
  });

  useEffect(() => {
    if (userProfile) {
      console.log('useLoginProfileLoad() success', userProfile);
      LoginProfileLoadedEvent.emit(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (error) {
      console.log('useLoginProfileLoad() fail', error);
    }
  }, [error]);

  useEffect(() => {
    console.log('useLoginProfileLoad() status changed:', status);
  }, [status]);

  useEffect(() => {
    console.log('useLoginProfileLoad() isFetching:', isFetching);
  }, [isFetching]);

  return userProfile;
}
