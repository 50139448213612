'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';

export default function TanStackProvider({ children }: { children: ReactNode }) {
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient(): QueryClient {
  if (browserQueryClient) {
    return browserQueryClient;
  }

  const newQueryCli = () =>
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60 * 1000, // 기본 캐싱 시간(1분)
          retry: (failureCount: number, error: any) => {
            // Don't retry for certain error responses
            if (
              error?.response?.status === 400 ||
              error?.response?.status === 401 ||
              error?.response?.status === 403
            ) {
              return false;
            }
            // Retry others just once
            return failureCount <= 1;
          },
        },
      },
    });

  if (typeof window === 'undefined') {
    return newQueryCli();
  } else {
    if (!browserQueryClient) {
      browserQueryClient = newQueryCli();
    }
    return browserQueryClient; // 기존 브라우저 클라이언트를 반환
  }
}
