'use client';

import { useCallback, useEffect, useState } from 'react';
import { LectureViewDialog, type LectureViewDialogProps } from '../../dialogs/LectureViewDialog';

interface DialogParams {
  id: 'LectureViewDialog';
  props: LectureViewDialogProps;
}

export function LectureViewDialogOpenEventHandler() {
  const [dialogParams, setDialogParams] = useState<DialogParams | undefined>(undefined);

  const openDialog = useCallback((lectureKey: string) => {
    setDialogParams({
      id: 'LectureViewDialog',
      props: {
        open: true,
        onClose: () => {
          setDialogParams(undefined);
        },
        lectureKey,
      },
    });
  }, []);

  useEffect(() => {
    const handleEvent = (event: CustomEvent<{ lectureKey: string }>) => {
      const lectureKey = event.detail?.lectureKey;
      if (!lectureKey) {
        console.log('ignore invalid lectureKey', lectureKey);
        return;
      }
      openDialog(lectureKey);
    };

    document.addEventListener('lecture-dialog-open', handleEvent as EventListener);
    return () => {
      document.removeEventListener('lecture-dialog-open', handleEvent as EventListener);
    };
  }, [openDialog]);

  if (!dialogParams) return null;

  return (
    <>{dialogParams.id === 'LectureViewDialog' && <LectureViewDialog {...dialogParams.props} />}</>
  );
}
